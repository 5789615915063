class EnquiryItem {
	constructor(data, type = null) {
		if (type == null) {
			this.category_id = data?.category_id ?? ''
			this.category_name = data?.category_name ?? ''
			this.product_id = data?.product_id ?? ''
			this.product_name = data?.product_name ?? ''
			this.brand = data?.brand ?? ''

			this.tc_required = data?.tc_required ?? false
			this.required_weight = data?.required_weight ?? null
			this.serviced_weight = data?.serviced_weight ?? null
			this.message = data?.message ?? ''
			this.price_per_kg = data?.price_per_kg ?? null
			this.ordered_weight = data?.ordered_weight ?? null
			this.allotted_weight = data?.allotted_weight ?? null
		} else if (type === 'ORDER_ITEM') {
			this.product_id = data?.product_id ?? null
			this.category_id = data?.category_id ?? null
			this.category_name = data?.category_name ?? null
			this.product_name = data?.product_name ?? null
			this.brands = data?.brand_ordered.split(', ') ?? null
			this.grade = data?.grade ?? null
			this.quantity_unit = data?.quantity_unit ?? null
			this.time_unit = data?.time_unit ?? null
			this.required_weight = data?.ordered_weight ?? null
			this.input_type = data?.input_type ?? null
			this.required_pieces = data?.required_pieces ?? null
			this.required_length = data?.required_length ?? null
			this.tc_required = data?.tc_required ?? null
			this.is_unknown_product = data?.is_unknown_product ?? null
			this.message = data?.message ?? null
			this.audio_message = data?.audio_message ?? null
			this.standard_length = data?.standard_length ?? null
			this.merged = data?.merged ?? null
			this.target_price = data?.target_price ?? null
		}
	}

	getOrderItemDetails() {
		return {
			category_id: this.category_id,
			category_name: this.category_name,
			product_id: this.product_id,
			product_name: this.product_name,
			serviced_weight: this.serviced_weight,
			price_per_kg: this.price_per_kg,
			ordered_weight: this.ordered_weight,
		}
	}
}

export default EnquiryItem
