import API from '../api'
import getUrl from './paths'
import convertPagableData from '../convertPagableData'

const getlogisticsPrices = (page = 0, size = 1000) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getlogisticsPrices, { page, size })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

const getlogisticsPricesById = (enquiryId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getlogisticsPricesById, { enquiryId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

const getLogisticsPricesRangeByPinCodes = (fromPinCode, toPinCode) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getLogisticsPricesRangeByPinCodes, { fromPinCode, toPinCode })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export { getlogisticsPrices, getlogisticsPricesById, getLogisticsPricesRangeByPinCodes }
