import API from '../api'
import getUrl from './paths'
import { User as UserModel } from '../../model'
import convertPagableData from '../convertPagableData'

export const getUserByID = (user_id) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getUserById, { user_id, removeDefault: true })
			.then((response) => {
				const user = new UserModel(response.data)
				resolve(user)
			})
			.catch((err) => reject(err))
	})

export const getUserByEmail = (emailId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getUserByEmail, { emailId })
			.then((response) => {
				const user = new UserModel(response.data)
				resolve(user)
			})
			.catch((err) => reject(err))
	})

export const getAllUsers = (params = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllClients, { ...params })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getTableUsers = (page = 0, userFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getTableUsers, { page, ...userFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getAllEmployees = (adminFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllEmployees, { ...adminFilters })
			.then((response) => {
				// resolve(convertPagableData(response.data));
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getAllOrgSuppliersApi = () =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllOrgSuppliers)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getAllFabricationPartners = () =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllFabricationPartners)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const downloadUsers = (ordersFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.downloadUsers, { ...ordersFilters })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getPastRecordsApi = (page = 0, ordersFilters = null, size = 10000) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getPastRecords, { page, size, ...ordersFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

// export const getrefreshAllPastRecordsApi = () =>
// 	new Promise((resolve, reject) => {
// 		API.get(getUrl.getrefreshAllPastRecords)
// 			.then((response) => {
// 				resolve(response.data)
// 			})
// 			.catch((err) => reject(err))
// 	})

export const unregisteredUsersApi = () =>
	new Promise((resolve, reject) => {
		API.get(getUrl.unregisteredUsers, { size: 10000 })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getBillingAddressesApi = (userId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getBillingAddresses, { userId, removeDefault: true })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getMobile = (mobile) => {
	mobile = mobile.replace('+', '')
	return new Promise((resolve, reject) => {
		API.get(getUrl.getMobile, { mobile })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(false))
	})
}

export const getMonthlyTarget = () =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getMonthlyTarget, {})
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(error)
			})
	})
